<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>
            {{ $t('report.harvard.report') }}
          </li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left"><span> {{ $t('report.harvard.report') }}</span></h2>
      </div>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0"/>
      <!-- Table -->
      <default-loading v-if="user_loading"/>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t('report.linkedin.userInfo') }}</th>
              <th scope="col">{{ $t('report.linkedin.mail') }}</th>
              <th scope="col">{{ $t('report.linkedin.content') }}</th>
              <th scope="col">{{ $t('report.linkedin.provider') }}</th>
              <th scope="col">{{ $t('report.linkedin.completionStatus') }}</th>
            </tr>
            </thead>
            <tbody class="list">
            <template v-for="item in items">
              <tr>
                <td>{{ item.learner_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.content_title }}</td>
                <td>{{ item.content_provider }}</td>
                <td>{{ item.is_completed ? $t('general.yes') : $t('general.no') }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {ERROR, GET_ITEMS, LOADING, MODULE_NAME} from "@/core/services/store/reports.module";

import store from '@/core/services'
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "HarvardBusinessReports",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(_MODULE_NAME, reports)
  },
  components: {DefaultLoading, Pagination},
  data() {
    return {
      pagination: null,
      items: [],
      error: null,
      page: 1,
    }
  },
  computed: {
    user_loading(){
      return store.getters[_MODULE_NAME + '/' + LOADING]
    }
  },
  methods: {
    filterResult() {
      this.error = null;
      let filters = {
        page: this.page,
      };

      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/harvard',
        filters: filters
      }).then(response => {
        if (response.status) {
          this.items = response.data.data;
        }
      });
    },

    getContent() {
      let filters = {
        page: 1,
      };

      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/harvard',
        filters: filters
      }).then(response => {

        this.pagination = response.data?.meta

        if (response.status) {
          this.items = response.data.data
        }
      });
    },

    setPage(page) {
      this.page = page;
      this.filterResult()
    },

  },
  mounted() {
    this.getContent();
  },

};
</script>

